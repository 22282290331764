import template from './AccordsCadres.Template.vue';
import { Vue, Component } from 'vue-property-decorator';
import AccordsCadresList from '@/components/AccordsCadres/AccordsCadresList.vue';

/**
 * Composant qui affiche la liste des accords-cadres.
 */
@Component({
    ...template,
    name: 'AccordsCadres',
    components: {
        AccordsCadresList,
    }
})
export default class AccordsCadres extends Vue {
}
