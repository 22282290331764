


























































































import { Mixins, Component, Watch, Prop } from 'vue-property-decorator';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';
import { AccordCadre } from '@/models';
import { AccordCadreCriteria } from '@/views/Administration/AccordsCadres/AccordCadreCriteria.model';
import { Resultats } from '@/shared/enums';
import { DataTableHeader, PagingAndSorting, Pagination, Result } from '@/shared/models';
import { ApiService } from '@/services/base/ApiService';
import { AxiosResponse } from 'axios';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { Getter } from 'vuex-class';
import { Societe } from '../../models';

@Component({
    name: 'AccordsCadresList'
})
export default class AccordsCadresList extends Mixins(GrilleMixin) {
        /**
     * Liste des accords-cadres.
     */
    public accordsCadres: AccordCadre[] = [];

    /**
     * Le nombre total d'accords-cadres.
     */
    public totalAccordsCadres!: number;

    /**
     * Définition des colonnes de la datatable.
     */
    public columns: DataTableHeader[] = [];

    /**
     * Le critère de recherche de la grille.
     */
    public accordCadreCriteria: AccordCadreCriteria = new AccordCadreCriteria();

    public canEdit: boolean = true;

    public loading: boolean = false;

    private resultats: Resultats[] = [Resultats.ALL, Resultats.ACTIF, Resultats.INACTIF];

    private resultat: number = Resultats.ACTIF.toKey();

    // Promesse pour récupérer le profil de l'utilisateur.
    @Getter(AuthStoreMethods.USER_PROFILE)
    public getUserProfile: Promise<any>;

    public societeIds: number[] = [];
    
    
    @Prop({ default: false })
    private editMode: boolean;

    /**
     * Charge les données.
     */
    public created() {
        // Définition des headers
        this.columns = [
            { text: 'Libellé', sortBy: 'Libelle' },
            { text: 'Responsable' },
            { text: 'Date Début' },
            { text: 'Date Fin' },
            { text: 'Volumes\nCEE classique' },
            { text: 'Volumes\nCEE précarité' },
            { text: 'Valorisations\nCEE classique' },
            { text: 'Valorisations\nCEE précarité' },
        ];
    }

    public mounted(): void {
        this.recupererSocietesUtilisateur().then(() => {
            this.getData();
        });
    }

    /*
     * Récupération des données.
     */
    public getData() {
        this.accordCadreCriteria.pagingAndSorting = PagingAndSorting.buildFromPaginationObject(this.pagination);
        this.accordCadreCriteria.resultats = this.resultat;
        this.accordCadreCriteria.societeIds = this.societeIds;
        this.accordCadreCriteria.isAllRequested = (this.$route.name ==='accords-cadres');
        this.fetchAccordsCadres(this.accordCadreCriteria);
    }

    public fetchAccordsCadres(criteria: AccordCadreCriteria) {
        this.loading = true;
        const accordCadreService = new ApiService<AccordCadre>('accordCadre/obtenirTous');
        return new Promise<AxiosResponse<Result<AccordCadre[]>>>((resolve, reject) => {
            return accordCadreService.getWhere(criteria.queryString).then((response) => {
                this.accordsCadres = response.data.data;
                this.totalAccordsCadres = response.data.totalCount;
                this.$set(this.pagination, 'totalItems', this.totalAccordsCadres);
                resolve(response);
            })
            .catch((error: { response: any; }) => {
                    reject(error.response);
                })
            .finally(() => this.loading = false);
        });
    }

    /**
     * Récupération des sociétés de l'utilisateur connecté.
     *
     */
    protected recupererSocietesUtilisateur(): Promise<void> {
        if(this.$route.name ==='accords-cadres') {
            return Promise.resolve();
        } else {
            const id = this.$route.name === 'modifier-utilisateur' ?
                        this.$route.params['id'] : 0;
            return new Promise<void>((resolve, reject) => {
                const societeService = new ApiService<Societe>('societe/ObtenirSocietesUtilisateurById');
                societeService.getWhere(`id=${id}`).then((response) => {
                    this.societeIds = (response.data.data as Societe[]).map((item) => item.id) || [];
                    resolve();
                }).catch((error: { response: any; }) => {
                    reject(error.response);
                });
            });

        }
    }
    /**
     * Watcher sur le criteria.
     */
    @Watch('accordCadreCriteria', { immediate: false, deep: true })
    public onCriteriaChange() {
        this.getData();
    }

    /**
     * Watcher sur la pagination.page et pagination.rowsPerPage.
     * @param value : nouvelle valeur de la propriété pagination.page.
     * @param oldValue : ancienne valeur de la propriété pagination.page.
     */
    @Watch('pagination.page')
    @Watch('pagination.rowsPerPage')
    public onPaginationOrRowsPerPageChanged(value: Pagination, oldValue: Pagination) {
        if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
            this.getData();
        }
    }
}
